import { MarketReportUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const MarketReportUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        hash
    }: NextUrl<MarketReportUrlFragment>): URL =>
        nextUrl(
            `/market-intelligence/market-report/${slug}`,
            siteContext.siteUrlMain,
            queryParameters,
            hash
        )
