import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuFormLinkFragment } from 'generated/graphql'
import { Theme } from 'content-types/Theme/Theme'
import { FormModal } from 'content-types/Form/Form.Modal/Form.Modal'

export const ComponentMenuFormLink: MenuObjectView<ComponentMenuFormLinkFragment> =
    ({
        linkText,
        variant,
        siteContext,
        pageContext,
        articleContext,
        theme,
        form
    }) =>
        maybe(form, (form,id) =>
            <li
                data-variant={variant?.variant}
                style={ Theme(theme?.data?.attributes) }
            >
                <FormModal
                    id={id}
                    {...form}
                    siteContext={siteContext}
                    pageContext={pageContext}
                    articleContext={articleContext}
                >
                    { linkText ?? form.title }
                </FormModal>
            </li>
        )
