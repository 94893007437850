'use client'

import { usePathname } from 'next/navigation'
import { ContentTypeLink } from 'utility/utility'
import { HomeLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { HomeUrl } from 'content-types/Home/Home.Url/Home.Url'

export const HomeLink: ContentTypeLink<HomeLinkFragment & { override?: string }> =
    ({
        children,
        className,
        title,
        seo,
        override,
        ...home
    }) => {

        const pathname = usePathname()
        const url = HomeUrl(home)

        return (
            <Link
                href={url}
                className={className}
                title={ seo?.metaTitle ?? title }
                data-prefix={ pathname?.indexOf(home.slug) === 1 }
                data-override={override}
                prefetch={false}
                siteContext={home.siteContext}
            >
                {children}
            </Link>
        )

    }
