'use client'

import { usePathname } from 'next/navigation'
import { ContentTypeLink } from 'utility/utility'
import { ActivityLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { ActivityUrl } from '../Activity.Url/Activity.Url'

export const ActivityLink: ContentTypeLink<ActivityLinkFragment & { override?: string }> =
    ({
        children,
        className,
        title,
        seo,
        override,
        ...activity
    }) => {

        const pathname = usePathname()
        const url = ActivityUrl(activity)

        return (
            <Link
                href={url}
                className={className}
                title={ seo?.metaTitle ?? title }
                data-prefix={ pathname?.indexOf(activity.slug) === 1 }
                data-override={override}
                prefetch={false}
                siteContext={activity.siteContext}
            >
                {children}
            </Link>
        )

    }
