'use client'

import { FC, SetStateAction, useState, Dispatch } from 'react'
import { contentTypeArray } from 'utility/utility'
import { PortfolioFilterFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

import styles from 'components/form/ComponentFormCheckboxField/ComponentFormCheckboxField.module.scss'

export const ComponentFormInterestPickerPortfolio: FC<PortfolioFilterFragment & {
    totalInterestCount: number,
    setTotalInterestCount: Dispatch<SetStateAction<number>>
}> =
    ({
        slug,
        title,
        interests,
        totalInterestCount,
        setTotalInterestCount
    }) => {

        const { register } = useFormContext()

        const [ selected, setSelected ] = useState<boolean>(false)
        const [ interestCount, setInterestCount ] = useState<number>(0)

        return (
            <>
                <dt key={`portfolio-${slug}`}>
                    <label className={styles.Field}>
                        <input
                            type='checkbox'
                            id={`portfolio-${slug}`}
                            {...register(`Portfolio: ${title}`, {})}
                            disabled={  interestCount !== 0}
                            defaultChecked={selected}
                            onChange={ ({target}): void => {
                                setSelected( target.checked )
                            } }
                        />
                        <span className={styles.Label}>{title}</span>
                    </label>
                </dt>
                <dd
                    key={`portfolio-${slug}-topics`}
                    data-open={selected}
                >
                    {

                        contentTypeArray(interests).map( ({slug,title}) =>
                            <label
                                key={slug}
                                className={styles.Field}
                            >
                                <input
                                    type='checkbox'
                                    id={`interest-${slug}`}
                                    {...register(`Interest: ${title}`, {})}
                                    onChange={ ({target}): void => {
                                        setInterestCount( interestCount + ( target.checked ? 1 : -1 ) )
                                        setTotalInterestCount( totalInterestCount + ( target.checked ? 1 : -1 ) )
                                    } }
                                />
                                <span className={styles.Label}>{title}</span>
                            </label>

                        )
                    }
                </dd>
            </>
        )

    }
