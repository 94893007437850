'use client'

import { usePathname } from 'next/navigation'
import { ContentTypeLink } from 'utility/utility'
import { MagazinePageLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'
import { MagazinePageUrl } from 'content-types/MagazinePage/MagazinePage.Url/MagazinePage.Url'

export const MagazinePageLink: ContentTypeLink<MagazinePageLinkFragment & { override?: string }> =
    ({
        children,
        className,
        title,
        seo,
        override,
        ...page
    }) =>
    {
        const pathname = usePathname()

        return (
            <Link
                href={MagazinePageUrl(page)}
                className={className}
                title={ seo?.metaTitle ?? title }
                data-prefix={ pathname?.includes(page.slug) }
                data-override={override}
                prefetch={false}
                siteContext={page.siteContext}
            >
                {children}
            </Link>
        )
    }
