import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuMagazinePageLinkFragment } from 'generated/graphql'
import { MagazinePageLink } from 'content-types/MagazinePage/MagazinePage.Link/MagazinePage.Link'

export const ComponentMenuMagazinePageLink: MenuObjectView<ComponentMenuMagazinePageLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        page,
        queryParameters,
        fragment,
        siteContext
    }) =>
        maybe(page, page =>
            <li data-variant={variant?.variant}>
                <MagazinePageLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...page}
                >
                    { linkText ?? page.title }
                </MagazinePageLink>
            </li>
        )
