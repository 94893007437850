'use client'

import { FC } from 'react'
import { ComponentFormSelectOptionFragment } from 'generated/graphql'

export const ComponentFormSelectOption: FC<ComponentFormSelectOptionFragment> =
    ({
        value,
        label
    }) =>
        <option value={value}>
            {label}
        </option>
