import { ContentTypeLink } from 'utility/utility'
import { EventSeriesPageLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'
import { EventSeriesPageUrl } from 'content-types/EventSeriesPage/EventSeriesPage.Url/EventSeriesPage.Url'

export const EventSeriesPageLink: ContentTypeLink<EventSeriesPageLinkFragment & { override?: string }> =
    ({
        children,
        className,
        title,
        seo,
        override,
        ...page
    }) =>
        <Link
            href={EventSeriesPageUrl(page)}
            className={className}
            title={ seo?.metaTitle ?? title }
            prefetch={false}
            data-override={override}
            siteContext={page.siteContext}
        >
            {children}
        </Link>
