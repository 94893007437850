import { InsightReportUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const InsightReportUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        hash
    }: NextUrl<InsightReportUrlFragment>): URL =>
        nextUrl(
            `/market-intelligence/insight-report/${slug}`,
            siteContext.siteUrlMain,
            queryParameters,
            hash
        )
