import { EventSeriesPageUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const EventSeriesPageUrl =
    ({
        slug,
        eventSeries,
        queryParameters,
        siteContext,
        hash
    }: NextUrl<EventSeriesPageUrlFragment>): URL =>
        nextUrl(
            `/${ eventSeries?.data?.attributes?.slug ?? '' }/${slug}`,
            siteContext.siteUrlEvents,
            queryParameters,
            hash
        )
