'use client'

import { useState, useEffect, useRef } from 'react'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { FormFragment } from 'generated/graphql'
import { FormContent } from 'components/form/FormContent/FormContent'
import { FormDynamicZone } from 'components/form/FormComponent/FormComponent'
import { Markdown } from 'components/shared/Markdown/Markdown'
import { ContentTypeView, type IJSON } from 'utility/utility'
import { RequestError } from 'mssql'

import styles from './Form.Form.module.scss'
import proseStyles from 'components/body/ComponentBodyProse/ComponentBodyProse.module.scss'

const FormSubmitted: ContentTypeView<{completionText: string}> = ({
    completionText,
    ...context
}) => {

    const completedElementRef = useRef<HTMLDivElement>(null)

    useEffect( () => {
        const current = completedElementRef?.current
        if ( current !== null ) {
            const dialog = current.closest('dialog')
            if ( dialog === null ) {
                const clientTop = current.getBoundingClientRect().top
                window.scrollTo({
                    top: clientTop + window.scrollY - 150,
                    behavior: 'smooth'
                })
            }
            else {
                dialog.scrollTop = 0
            }
        }

    },[])

    return (
        <div
            ref={completedElementRef}
            className={`${styles.Submitted} ${proseStyles.Prose}`}
        >
            <Markdown {...context}>
                {completionText}
            </Markdown>
        </div>
    )

}

type FormError = {
    info: {
        message: string
    }
}


export const FormForm: ContentTypeView<FormFragment & {
    id?: string | null,
    pageTitle?: string,
    code?: string
}> = ({
    id,
    name,
    target,
    completionText,
    body,
    ...context
}) => {

    if ( id === undefined || id === null ) {
        console.error(`Form ${name} has no ID`)
    }

    const [ submitted, setSubmitted ] = useState<boolean>(false)
    const [ errorMessage, setErrorMessage ] = useState<string>('')
    const [ submittable, setSubmittable ] = useState<boolean>(true) //stops multiple submissions while processing

    const methods = useForm({
        shouldUseNativeValidation: false,
        mode: 'all'
    })

    const { executeRecaptcha } = useGoogleReCaptcha()

    const onSubmit: SubmitHandler<IJSON> =
        async (data) => {

            if ( !submittable ) {
                return
            }
            setSubmittable(false)

            if ( !executeRecaptcha ) {
                console.log('Recaptcha not yet available')
                return false
            }

            const reCaptchaToken = await executeRecaptcha()

            const { interests_hidden, ...submittedData } = data

            const formData = {
                ...submittedData,
                __internal: undefined
            }

            const response = await fetch(target, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...formData,
                    reCaptchaToken,
                    context: {
                        site: context.siteContext.site,
                        page: context.articleContext.title,
                        form: name,
                        product: context.articleContext.code,
                        ...Object.fromEntries(new URLSearchParams(window.location.search.substring(1)))
                    },
                    id: id
                })
            })

            if ( response.status === 201 ) {
                setErrorMessage('')
                setSubmitted(true)
            }
            else if ( response.status === 504 ) {
                setErrorMessage('The service is current unavailable. Please try again later.')
            }
            else {
                const mssqlError: RequestError = await response.json()
                console.log(mssqlError)
                const formError = mssqlError.originalError as unknown as FormError
                setErrorMessage(`${formError?.info?.message ?? 'A service error has occurred.'} Please try again later.`)
            }

            setSubmittable(true)
        }



    return (
        submitted ?
            <FormSubmitted
                completionText={completionText}
                {...context}
            />
            :
            <FormProvider {...methods}>
                <form
                    className={styles.Form}
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <FormContent
                        body={body as FormDynamicZone[]}
                        {...context}
                        articleContext={{}}
                    />
                    <input type="submit" />
                    <p className={styles.Error}>{errorMessage}</p>
                    <p className={styles.Recaptcha}>
                        This site is protected by reCAPTCHA and the Google <a target='_blank' href="https://policies.google.com/privacy">Privacy Policy</a> and <a target='_blank' href="https://policies.google.com/terms">Terms of Service</a> apply.
                        You are also protected by AMI’s <a href="https://www.amiplastics.com/privacy-policy" target='_blank'>Privacy Policy</a>.
                    </p>
                </form>
            </FormProvider>
    )

}
