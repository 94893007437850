import { type CSSProperties } from 'react'
import { ThemeFragment } from 'generated/graphql'

export const Theme = (theme?: ThemeFragment | null): CSSProperties | undefined =>

  theme === undefined || theme === null ? undefined :
    Object.fromEntries(
        theme.colours.map( ({token,value}) =>
            [`--colour-${token.replaceAll('_','-')}`,value]
        )
    )
