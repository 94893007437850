'use client'

import { FC } from 'react'
import { useRouter } from 'next/navigation'

import { nextUrl } from 'utility/utility'
import { type SiteContext } from 'context/SiteContext'

import styles from 'styles/button.module.scss'

const federatedSignout = async (): Promise<void> => {

    const csrfResponse = await fetch('/api/auth/csrf')
    const csrfToken = await csrfResponse.text()

    await fetch('/api/auth/signout', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: csrfToken
    })

}

export const LogoutButton: FC<{siteContext: SiteContext}> = ({siteContext}) => {

    const router = useRouter()
    const federatedSignoutUrl = nextUrl(
        `/api/auth/federated-signout?url=${window.location.href}`,
        siteContext.siteUrl
    ).toString()

    const handleSignOut = (): void => {
        void (async (): Promise<void> => {
            await federatedSignout()
            router.push(federatedSignoutUrl)
        })()
    }

    return (
        <button
            className={styles.Link}
            onClick={handleSignOut}
        >
            Sign out
        </button>
    )

}

