import { PortfolioUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const PortfolioUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        hash
    }: NextUrl<PortfolioUrlFragment>): URL =>
        nextUrl(
            `/industries/${slug}`,
            siteContext.siteUrlMain,
            queryParameters,
            hash
        )
