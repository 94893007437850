'use client'

import {
    ComponentBodyProseFragment,
    ComponentFormCaptureFieldFragment,
    ComponentFormCheckboxFieldFragment,
    ComponentFormEmailFieldFragment,
    ComponentFormFieldsetFragment,
    ComponentFormHiddenFieldFragment,
    ComponentFormSelectFieldFragment,
    ComponentFormTextboxFieldFragment,
    ComponentFormTextFieldFragment,
    ComponentFormTelephoneNumberFieldFragment,
    ComponentFormInterestPickerFragment
} from 'generated/graphql'

export type FormDynamicZone =
    | ComponentBodyProseFragment
    | ComponentFormCaptureFieldFragment
    | ComponentFormCheckboxFieldFragment
    | ComponentFormEmailFieldFragment
    | ComponentFormFieldsetFragment
    | ComponentFormHiddenFieldFragment
    | ComponentFormSelectFieldFragment
    | ComponentFormTextboxFieldFragment
    | ComponentFormTextFieldFragment
    | ComponentFormTelephoneNumberFieldFragment
    | ComponentFormInterestPickerFragment
    | null

import { ComponentBodyProse } from 'components/body/ComponentBodyProse/ComponentBodyProse'
import { ComponentFormCaptureField } from 'components/form/ComponentFormCaptureField/ComponentFormCaptureField'
import { ComponentFormCheckboxField } from 'components/form/ComponentFormCheckboxField/ComponentFormCheckboxField'
import { ComponentFormEmailField } from 'components/form/ComponentFormEmailField/ComponentFormEmailField'
import { ComponentFormFieldset } from 'components/form/ComponentFormFieldset/ComponentFormFieldset'
import { ComponentFormHiddenField } from 'components/form/ComponentFormHiddenField/ComponentFormHiddenField'
import { ComponentFormSelectField } from 'components/form/ComponentFormSelectField/ComponentFormSelectField'
import { ComponentFormMultipleSelectField } from 'components/form/ComponentFormMultipleSelectField/ComponentFormMultipleSelectField'
import { ComponentFormTextboxField } from 'components/form/ComponentFormTextboxField/ComponentFormTextboxField'
import { ComponentFormTextField } from 'components/form/ComponentFormTextField/ComponentFormTextField'
import { ComponentFormTelephoneNumberField } from 'components/form/ComponentFormTelephoneNumberField/ComponentFormTelephoneNumberField'
import { ComponentFormInterestPicker } from 'components/form/ComponentFormInterestPicker/ComponentFormInterestPicker'

import { BodyObjectView } from 'utility/utility'

export const FormComponent: BodyObjectView<FormDynamicZone> =
    (component) => {

        const { __typename } = component

        switch (__typename) {

            case 'ComponentBodyProse':
                return <ComponentBodyProse {...component} />

            case 'ComponentFormCaptureField':
                return <ComponentFormCaptureField {...component} />

            case 'ComponentFormCheckboxField':
                return <ComponentFormCheckboxField {...component} />

            case 'ComponentFormEmailField':
                return <ComponentFormEmailField {...component} />

            case 'ComponentFormFieldset':
                return <ComponentFormFieldset {...component} />

            case 'ComponentFormHiddenField':
                return <ComponentFormHiddenField {...component} />

            case 'ComponentFormSelectField':
                return  component.allowMultiple === null || !component.allowMultiple ?
                          <ComponentFormSelectField {...component} />
                        : <ComponentFormMultipleSelectField {...component} />

            case 'ComponentFormTextboxField':
                return <ComponentFormTextboxField {...component} />

            case 'ComponentFormTextField':
                return <ComponentFormTextField {...component}  />

            case 'ComponentFormTelephoneNumberField':
                return <ComponentFormTelephoneNumberField {...component} />

            case 'ComponentFormInterestPicker':
                return <ComponentFormInterestPicker {...component} />

            default:
                return <></>

        }

    }
