import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuPortfolioLinkFragment } from 'generated/graphql'
import { PortfolioLink } from 'content-types/Portfolio/Portfolio.Link/Portfolio.Link'

export const ComponentMenuPortfolioLink: MenuObjectView<ComponentMenuPortfolioLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        portfolio,
        queryParameters,
        fragment,
        siteContext
    }) =>
        maybe(portfolio, portfolio =>
            <li data-variant={variant?.variant}>
                <PortfolioLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...portfolio}
                >
                    { linkText ?? portfolio.title }
                </PortfolioLink>
            </li>
        )
