import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuActivityLinkFragment } from 'generated/graphql'
import { ActivityLink } from 'content-types/Activity/Activity.Link/Activity.Link'

export const ComponentMenuActivityLink: MenuObjectView<ComponentMenuActivityLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        activity,
        queryParameters,
        fragment,
        siteContext
    }) =>
        maybe(activity, activity =>
            <li data-variant={variant?.variant}>
                <ActivityLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...activity}
                >
                    { linkText ?? activity.title }
                </ActivityLink>
            </li>
        )
