import { ContentTypeLink } from 'utility/utility'
import { PageLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'
import { PageUrl } from 'content-types/Page/Page.Url/Page.Url'

export const PageLink: ContentTypeLink<Omit<PageLinkFragment,'site'> & Partial<Pick<PageLinkFragment,'site'>> & { override?: string }> =
    ({
        children,
        className,
        title,
        seo,
        override,
        ...page
    }) =>
        <Link
            href={PageUrl(page)}
            className={className}
            title={ seo?.metaTitle ?? title }
            data-override={override}
            prefetch={false}
            siteContext={page.siteContext}
        >
            {children}
        </Link>
