import { ReactElement } from 'react'
import { type EntityResponse } from 'utility/contentType'
import { contentType } from 'utility/contentType'


export function maybe<T>(
    x: EntityResponse<T> | undefined | null,
    fn: (x: T, id?: string | null) => ReactElement
): ReactElement | null | undefined {
    const id = x?.data?.id
    const entity = contentType(x ?? null)
    return (
        entity === null ? <></> : fn(entity as T, id)
    )
}
