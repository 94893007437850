import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuSocialMediaChannelLinkFragment } from 'generated/graphql'
import { SocialMediaChannelLink } from 'content-types/SocialMediaChannel/SocialMediaChannel.Link/SocialMediaChannel.Link'

export const ComponentMenuSocialMediaChannelLink: MenuObjectView<ComponentMenuSocialMediaChannelLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        siteContext,
        channel
    }) =>
        maybe(channel, channel =>
            <li data-variant={variant?.variant}>
                <SocialMediaChannelLink
                    className={contextStyles?.Link}
                    siteContext={siteContext}
                    {...channel}
                >
                    { linkText }
                </SocialMediaChannelLink>
            </li>
        )
