import { EventSeriesUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const EventSeriesUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        hash
    }: NextUrl<EventSeriesUrlFragment>): URL =>
        nextUrl(
            `/${slug}`,
            siteContext.siteUrlEvents,
            queryParameters,
            hash
        )
