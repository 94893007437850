import { MenuObjectView } from 'utility/utility'
import { ComponentMenuExternalLinkFragment } from 'generated/graphql'

export const ComponentMenuExternalLink: MenuObjectView<ComponentMenuExternalLinkFragment> =
    ({
        linkText,
        variant,
        linkUrl,
        contextStyles
    }) =>
        <li data-variant={variant?.variant}>
            <a
                key={linkUrl}
                href={linkUrl}
                title={linkText ?? undefined}
                target='_blank'
                rel='noreferrer'
                className={contextStyles?.Link}
            >
                {linkText}
            </a>
        </li>
