export const formatDateRange = (startDateString: string, endDateString: string) => {

    const startDate = new Date(startDateString)
    const endDate   = new Date(endDateString)

    return (
        startDate.getMonth() === endDate.getMonth() && startDate.getDate() === endDate.getDate()
            ? startDate.toLocaleString('en-GB', { day: 'numeric', month: 'long'})
            : startDate.getMonth() == endDate.getMonth()
                ? `${startDate.toLocaleString('en-GB', { day: 'numeric'})}-${endDate.toLocaleString('en-GB', { day: 'numeric', month: 'long'})}`
                : `${startDate.toLocaleString('en-GB', { day: 'numeric', month: 'long' })}–${endDate.toLocaleString('en-GB', { day: 'numeric', month: 'long'})}`
    )

}
