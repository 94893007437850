import { MagazinePageUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const MagazinePageUrl =
    ({
        slug,
        magazine,
        queryParameters,
        siteContext,
        hash
    }: NextUrl<MagazinePageUrlFragment>): URL =>
        nextUrl(
            `/${ magazine?.data?.attributes?.slug ?? '' }/${slug}`,
            siteContext.siteUrlMagazines,
            queryParameters,
            hash
        )
