import { MenuObjectView } from 'utility/utility'
import { ComponentMenuExternalImageLinkFragment } from 'generated/graphql'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import styles from './ComponentMenuExternalImageLink.module.scss'
import { Enum_Componentsharedcsssize_Unit as SizeUnit } from 'generated/graphql'

type Size = {
    value: number,
    unit: SizeUnit
}

const cssSize = ( size: Size | null ): string =>
    size === null ? 'auto' :
        `${ size.value }${ size.unit === SizeUnit.Percentage ? '%' : size.unit }`

export const ComponentMenuExternalImageLink: MenuObjectView<ComponentMenuExternalImageLinkFragment> =
    ({
        linkUrl,
        image,
        idealHeight,
        idealWidth,
        contextStyles
    }) =>
        <li key={linkUrl}>
            <a
                key={linkUrl}
                href={linkUrl}
                target='_blank'
                rel='noreferrer'
                className={contextStyles?.Link}
                style={{width: cssSize(idealWidth), height: cssSize(idealHeight), padding: 0}}
            >
                <ComponentImagePicture
                    {...image}
                    className={styles.Image}
                />
            </a>
        </li>
