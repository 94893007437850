'use client'

import { BodyObjectView } from 'utility/utility'
import { useSession } from 'next-auth/react'
import { ComponentBodyLoginButtonFragment } from 'generated/graphql'

import { LoginButton } from './LoginButton'
import { LogoutButton } from './LogoutButton'

import styles from './ComponentBodyLoginButton.module.scss'

export const ComponentBodyLoginButton: BodyObjectView<ComponentBodyLoginButtonFragment> =
    ({siteContext}) => {

        const { data: session, status } = useSession()

        return <span className={styles.LoginButton} >
            {
                status === 'authenticated' ?
                    <span key='authenticated'>
                        <span>Signed in as <strong>{session.user?.name }</strong></span>
                        <LogoutButton siteContext={siteContext}/>
                    </span>
                :
                    <span key='not-authenticated'>
                        <LoginButton />
                    </span>
            }
        </span>

    }
