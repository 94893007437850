'use client'

import { FormObjectView, key, sanitiseName } from 'utility/utility'
import { ComponentFormTextFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

export const ComponentFormTextField: FormObjectView<ComponentFormTextFieldFragment> =
    ({
        name,
        label,
        placeholder,
        pattern,
        required,
        ...object
    }) => {

        const { register, formState: { errors } } = useFormContext()

        const sanitisedName = sanitiseName(name)

        const validation =
              pattern !== null && pattern !== '' ? { pattern: new RegExp(pattern) }
            : {}

        return (
            <>
                <label htmlFor={key(object)}>
                    <input
                        type='text'
                        id={key(object)}
                        placeholder={placeholder ?? label}
                        {...register(sanitisedName, {
                            required,
                            setValueAs: (v: string): string => v.trim(),
                            ...validation
                        })}
                    />
                    <span>{label}</span>
                </label>
                {
                    !required || errors[sanitisedName] === undefined ? <></> :
                        <span>This field is required</span>
                }
            </>
        )

    }

