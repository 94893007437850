import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuHomeLinkFragment } from 'generated/graphql'
import { HomeLink } from 'content-types/Home/Home.Link/Home.Link'

export const ComponentMenuHomeLink: MenuObjectView<ComponentMenuHomeLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        home,
        queryParameters,
        fragment,
        siteContext
    }) =>
        maybe(home, home =>
            <li data-variant={variant?.variant}>
                <HomeLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...home}
                >
                    { linkText ?? home.title }
                </HomeLink>
            </li>
        )
