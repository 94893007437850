'use client'

import { useState } from 'react'
import { FormObjectView, sanitiseName } from 'utility/utility'
import { ComponentFormSelectFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

import styles from './ComponentFormMultipleSelectField.module.scss'
import checkboxStyles from 'components/form/ComponentFormCheckboxField/ComponentFormCheckboxField.module.scss'

export const ComponentFormMultipleSelectField: FormObjectView<ComponentFormSelectFieldFragment> =
    ({
        name,
        label,
        required,
        options = []
    }) => {

        const { register, formState: { errors } } = useFormContext()

        const sanitisedName = sanitiseName(name)

        const [ totalCount, setTotalCount ] = useState<number>(0)

        return (
            <fieldset className={styles.Picker}>
                <legend>{label}</legend>
                <input
                    type='hidden'
                    {...register(`${sanitisedName}-hidden`, {
                        validate: () => !required || totalCount !== 0
                    })}
                />
                {
                    options.map( ({label,value}) =>
                        <label
                            key={label}
                            className={checkboxStyles.Field}
                        >
                            <input
                                type='checkbox'
                                id={`${sanitisedName}-${value}`}
                                {...register(value, {})}
                                onChange={ ({target}): void => {
                                    setTotalCount( totalCount + ( target.checked ? 1 : -1 ) )
                                } }
                            />
                            <span className={checkboxStyles.Label}>{label}</span>
                        </label>
                    )
                }
                {
                    !required || errors[`${sanitisedName}-hidden`] === undefined ? <></> :
                        <span className={styles.Error}>Please pick at least one option</span>
                }
            </fieldset>
        )

    }
