import { FC, PropsWithChildren } from 'react'

import styles from './MetadataTag.module.scss'

type MetadataTagData = {
    className?: string
}

export const MetadataTag: FC<PropsWithChildren<MetadataTagData>> =
    ({
        children,
        className = ''
    }) =>
        <span className={`${styles.MetadataTag} ${className}`}>
            {children}
        </span>
