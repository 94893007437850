'use client'

import { type FormDynamicZone, FormComponent } from 'components/form/FormComponent/FormComponent'
import { DynamicZoneView, key } from 'utility/utility'

export const FormContent: DynamicZoneView<{ body: FormDynamicZone[] }> =
    ({
        body = [],
        ...context
    }) =>
        <>
            {
                body.map( component =>
                    component === null ? <></> :
                        <FormComponent
                            key={key(component)}
                            {...context}
                            {...component}
                        />
                )
            }
        </>
