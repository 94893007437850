import { ContentTypeLink } from 'utility/utility'
import { EventSeriesLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { EventSeriesUrl } from 'content-types/EventSeries/EventSeries.Url/EventSeries.Url'

export const EventSeriesLink: ContentTypeLink<EventSeriesLinkFragment & { override?: string }> =
    ({
        children,
        className,
        title,
        seo,
        override,
        ...eventSeries
    }) =>
        <Link
            href={EventSeriesUrl(eventSeries)}
            className={className}
            title={ seo?.metaTitle ?? title }
            data-override={override}
            prefetch={false}
            siteContext={eventSeries.siteContext}
        >
            {children}
        </Link>
