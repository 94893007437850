import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuPageLinkFragment } from 'generated/graphql'
import { PageLink } from 'content-types/Page/Page.Link/Page.Link'

export const ComponentMenuPageLink: MenuObjectView<ComponentMenuPageLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        page,
        queryParameters,
        fragment,
        siteContext
    }) =>
        maybe(page, page =>
            <li data-variant={variant?.variant}>
                <PageLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...page}
                >
                    { linkText ?? page.title }
                </PageLink>
            </li>
        )
