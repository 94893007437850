'use client'

import { FC } from 'react'
import { signIn } from 'next-auth/react'

import styles from 'styles/button.module.scss'

const handleSignIn = (): void => {
    void (async (): Promise<void> => {
        await signIn('azure-ad-b2c')
    })()
}

export const LoginButton: FC = () =>
    <button
        className={styles.Link}
        onClick={handleSignIn}
    >
        Sign in
    </button>
