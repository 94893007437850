import { FC } from 'react'
import { Maybe } from 'generated/graphql'

import { type SiteContext } from 'context/SiteContext'
import { type PageContext } from 'context/PageContext'
import { type ArticleContext } from 'context/ArticleContext'

export type Entity<T> = {
  readonly __typename?: string
  readonly attributes?: Maybe<T>
  readonly id?: Maybe<string>
}

export type EntityResponse<T> = {
  readonly __typename?: string
  readonly data?: Maybe<Entity<T>>
}

export type EntityResponseCollection<T> = {
  readonly __typename?: string
  readonly data: ReadonlyArray<Entity<T>>
}

export type RelationResponseCollection<T> = {
    readonly __typename?: string
    readonly data: ReadonlyArray<Entity<T>>
  }

export function contentType<T>(x: EntityResponse<T> | null): NonNullable<Maybe<T>> | null {
  return x?.data?.attributes ?? null
}

export function contentTypeArray<T>(x?: EntityResponseCollection<T> | null): ReadonlyArray<T> {
  return x?.data.map( ({attributes}) => attributes as T) ?? []
}

export function contentTypeFromArray<T>(x?: EntityResponseCollection<T>): NonNullable<Maybe<T>> | null {
  return x?.data[0]?.attributes ?? null
}

export type ContentTypeView<T> = FC<T & {
  siteContext: SiteContext
  pageContext: PageContext
  articleContext: ArticleContext
}>
