import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuMagazineLinkFragment } from 'generated/graphql'

import { MagazineLink } from 'content-types/Magazine/Magazine.Link/Magazine.Link'

export const ComponentMenuMagazineLink: MenuObjectView<ComponentMenuMagazineLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        magazine,
        queryParameters,
        fragment,
        siteContext
    }) =>
        maybe(magazine, magazine =>
            <li data-variant={variant?.variant}>
                <MagazineLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...magazine}
                >
                    { linkText ?? magazine.title }
                </MagazineLink>
            </li>
        )
