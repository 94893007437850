import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuEventSeriesPageLinkFragment } from 'generated/graphql'
import { EventSeriesPageLink } from 'content-types/EventSeriesPage/EventSeriesPage.Link/EventSeriesPage.Link'

export const ComponentMenuEventSeriesPageLink: MenuObjectView<ComponentMenuEventSeriesPageLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        page,
        siteContext,
        fragment,
        queryParameters
    }) =>
        maybe(page, page =>
            <li data-variant={variant?.variant}>
                <EventSeriesPageLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...page}
                >
                    { linkText ?? page.title }
                </EventSeriesPageLink>
            </li>
        )
