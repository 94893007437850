import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuEventSeriesLinkFragment } from 'generated/graphql'

import { EventSeriesLink } from 'content-types/EventSeries/EventSeries.Link/EventSeries.Link'

export const ComponentMenuEventSeriesLink: MenuObjectView<ComponentMenuEventSeriesLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        eventSeries,
        queryParameters,
        fragment,
        siteContext
    }) =>
        maybe(eventSeries, eventSeries =>
            <li data-variant={variant?.variant}>
                <EventSeriesLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...eventSeries}
                >
                    { linkText ?? eventSeries.title }
                </EventSeriesLink>
            </li>
        )
