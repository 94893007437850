'use client'
import { usePathname } from 'next/navigation'
import { ContentTypeLink } from 'utility/utility'
import { MagazineLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { MagazineUrl } from 'content-types/Magazine/Magazine.Url/Magazine.Url'

export const MagazineLink: ContentTypeLink<MagazineLinkFragment & { override?: string }> =
    ({
        children,
        className,
        title,
        seo,
        override,
        ...magazine
    }) =>
    {
        const pathname = usePathname()

        return (
            <Link
                href={MagazineUrl(magazine)}
                className={className}
                title={ seo?.metaTitle ?? title }
                data-prefix={ pathname?.trim().endsWith(magazine.slug) }
                data-override={override}
                prefetch={false}
                siteContext={magazine.siteContext}
            >
                {children}
            </Link>
        )
    }
