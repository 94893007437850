import { FormObjectView, key } from 'utility/utility'
import { FieldsetFragment } from 'generated/graphql'
import { FormContent } from 'components/form/FormContent/FormContent'
import { type FormDynamicZone } from 'components/form/FormComponent/FormComponent'

import styles from './Fieldset.Fieldset.module.scss'

export const FieldsetFieldset: FormObjectView<FieldsetFragment> = ({
    title,
    body,
    siteContext,
    pageContext,
    articleContext,
    ...object
}) =>
    <fieldset key={key(object)} className={styles.Fieldset}>
        <legend>{title}</legend>
        <FormContent
            body={body as FormDynamicZone[]}
            siteContext={siteContext}
            pageContext={pageContext}
            articleContext={articleContext}
        />
    </fieldset>
