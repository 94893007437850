import { EventUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const EventUrl =
    ({
        slug,
        siteContext
    }: NextUrl<EventUrlFragment>): URL =>
        nextUrl(
            `/events/${slug}`,
            siteContext.siteUrlMain
        )
