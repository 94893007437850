import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuEventLinkFragment } from 'generated/graphql'
import { EventLink } from 'content-types/Event/Event.Link/Event.Link'

export const ComponentMenuEventLink: MenuObjectView<ComponentMenuEventLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        event,
        queryParameters,
        siteContext
    }) =>
        maybe(event, event =>
            <li data-variant={variant?.variant}>
                <EventLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    siteContext={siteContext}
                    {...event}
                >
                    { linkText ?? event.title }
                </EventLink>
            </li>
        )
