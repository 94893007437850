import { TopicUrlFragment, Maybe, Page } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

type TopicUrlProps = TopicUrlFragment & {
    linkOverridePage?: ({
        readonly __typename?: "PageEntityResponse";
    } & {
        readonly data: Maybe<({
            readonly __typename?: "PageEntity";
        } & {
            readonly attributes: Maybe<({
                readonly __typename?: "Page";
            } & Pick<Page, "slug">)>;
        })>;
    }) | null
}

export const TopicUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        linkOverridePage,
        hash
    }: NextUrl<TopicUrlProps>): URL =>
        nextUrl(
            linkOverridePage?.data?.attributes?.slug ?? `/industries/topic/${slug}`,
            siteContext.siteUrlMain,
            queryParameters,
            hash
        )
