'use client'

import { FC } from 'react'
import { ReactSVG } from 'react-svg'
import { strapiPublicImage } from 'utility/utility'
import { Media } from 'content-types/UploadFile/UploadFile'

import styles from './UploadFile.Svg.module.scss'

export const UploadFileSvg: FC<Media> = ({
    url,
    className,
    width,
    height,
    style
}) =>
    <ReactSVG
        className={`${styles.Svg} ${className ?? ''}`}
        src={strapiPublicImage(url)}
        wrapper='span'
        style={style}
        beforeInjection={(svg): void => {
            if ( className !== undefined ) {
                className.split(' ').forEach( (cn) => {
                    const className = cn.trim()
                    if ( className ) {
                        svg.classList.add(className)
                    }
                })
            }
        }}
    />
