import { FC } from 'react'
import { strapiPublicImage } from 'utility/utility'
import type { Media } from '../UploadFile'

export const UploadFileImage: FC<Media> = ({
    url,
    className,
    alternativeText,
    formats,
    style,
    sizes,
    loading,
    mime
}) => {
    // const sources =
    //     formats === null ? [] :
    //         (Object.values(formats))
    //             .sort((a, b) => b.width - a.width)

    return (
        <picture className={className}>
            {/* {
                formats === null ? <></> :
                <source
                        srcSet={
                            Object.values(formats)
                                .sort( (a, b) => a.width - b.width )
                                .map( ({url,width}) => `${strapiPublicImage(url)} ${width}w` )
                                .join(', ')
                        }
                        media='( min-width: 0px )'
                    />
            } */}

            {/* {
                sources.map( ({hash,url,width}) =>
                    <source
                        key={hash}
                        srcSet={strapiPublicImage(url)}
                        media={`(min-resolution: 190dpi) and (min-width: ${Math.round(width/2.5)}px)`}
                    />
                )
            }
            {
                sources.map( ({hash,url,width}) =>
                    <source
                        key={hash}
                        srcSet={strapiPublicImage(url)}
                        media={`(min-width: ${width}px)`}
                    />
                )
            } */}
            {
                
                formats === null || mime === "image/gif" ?
                    <img
                        src={strapiPublicImage(url)}
                        alt={alternativeText ?? undefined}
                        style={style}
                        loading={ loading ?? 'lazy' }
                        decoding='async'
                    />
                :
                    <img
                        alt={alternativeText ?? undefined}
                        style={style}
                        loading={ loading ?? 'lazy' }
                        decoding='async'
                        srcSet={
                            Object.values(formats)
                                .sort( (a, b) => a.width - b.width )
                                .map( ({url,width}) => `${strapiPublicImage(url)} ${width}w` )
                                .join(', ')
                        }
                        sizes={sizes}
                        src={strapiPublicImage(url)}
                    />
            }
        </picture>
    )

}
