import { HomeUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const HomeUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        hash
    }: NextUrl<HomeUrlFragment>): URL =>
        nextUrl(
            `/${ slug === 'home' ? '' : slug }`,
            siteContext.siteUrlMain,
            queryParameters,
            hash
        )
