'use client'

import { useState, PropsWithChildren } from 'react'
import { Modal } from 'react-dialog-polyfill'
import { FormFragment } from 'generated/graphql'
import { ContentTypeView } from 'utility/utility'
import { FormForm } from 'content-types/Form/Form.Form/Form.Form'

import styles from './Form.Modal.module.scss'
import buttonStyles from 'styles/button.module.scss'

export const FormModal: ContentTypeView<PropsWithChildren<FormFragment & {
    id?: string | null,
    contextStyles?: { readonly [key: string]: string }
    pageTitle?: string,
    code?: string
}>> =
    ({
        id,
        children,
        contextStyles,
        siteContext,
        pageContext,
        articleContext,
        pageTitle,
        code,
        ...form
    }) => {

        const [ open, setOpen ] = useState<boolean>(false)

        return (
            <>
                <Modal
                    id={`form${id}`}
                    className={`${styles.Modal} ${contextStyles?.Modal ?? ''}`}
                    open={open}
                >
                    <header>
                        <h1>{form.title}</h1>
                        <button
                            onClick={ () => setOpen(false) }
                            className={buttonStyles.Link}
                        >
                            Close
                        </button>
                    </header>
                    <FormForm
                        {...form}
                        id={id}
                        siteContext={siteContext}
                        pageContext={pageContext}
                        articleContext={articleContext}
                        pageTitle={pageTitle}
                        code={code}
                    />
                </Modal>
                <button
                    onClick={ () => setOpen(true) }
                    className={`${buttonStyles.Link} ${contextStyles?.Link ?? ''}`}
                >
                    {children}
                </button>
            </>

        )

    }
