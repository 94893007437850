import { ContentTypeLink } from 'utility/utility'
import { EventLinkFragment } from 'generated/graphql'
import { Link } from 'components/shared/Link'

import { EventUrl } from 'content-types/Event/Event.Url/Event.Url'

export const EventLink: ContentTypeLink<EventLinkFragment> =
    ({
        children,
        className,
        title,
        seo,
        ...event
    }) =>
        <Link
            href={EventUrl(event)}
            className={className}
            title={ seo?.metaTitle ?? title }
            prefetch={false}
            siteContext={event.siteContext}
        >
            {children}
        </Link>
