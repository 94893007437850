export const Sizes = {
    Featured: '(min-width: 0px) 640px',
    Full: `
        (max-width:   156px)   256px,
        (max-width:   284px)   384px,
        (max-width:   540px)   640px,
        (max-width:   650px)   750px,
        (max-width:   728px)   828px,
        (max-width:   980px)  1080px,
        (max-width:  1100px)  1200px,
        (max-width:  1820px)  1920px,
        (max-width:  1948px)  2048px,
        (min-width:  1949px)  3840px
    `,
    Summary: '(min-width: 0px) 640px'
} as const