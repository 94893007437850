'use client'

import { FormObjectView, key, sanitiseName } from 'utility/utility'
import { ComponentFormCheckboxFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

import styles from './ComponentFormCheckboxField.module.scss'

export const ComponentFormCheckboxField: FormObjectView<ComponentFormCheckboxFieldFragment & { type?: string }> =
    ({
        name,
        label,
        required,
        type,
        ...object
    }) => {

        const { register, formState: { errors } } = useFormContext()

        const sanitisedName = sanitiseName(name)

        return (
            <>
                <label className={styles.Field}>
                    <input
                        type='checkbox'
                        id={key(object)}
                        {...register(sanitisedName, {
                            required,
                        })}
                    />
                    {
                        type === undefined ? <></> :
                            <span className={styles.Type}>{type}</span>
                    }
                    <span className={styles.Label}>{label}</span>
                </label>
                {
                    !required || errors[sanitisedName] === undefined ? <></> :
                        <span>This field is required</span>
                }
            </>
        )

    }
