import { PageUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const PageUrl =
    ({
        slug,
        site,
        queryParameters,
        siteContext,
        hash
    }: NextUrl<Omit<PageUrlFragment,'site'> & Partial<Pick<PageUrlFragment,'site'>>>): URL =>
        nextUrl(
            `/${slug}`,
              site?.data?.attributes?.slug === 'events' ? siteContext.siteUrlEvents
            : site?.data?.attributes?.slug === 'magazines' ? siteContext.siteUrlMagazines
            : siteContext.siteUrlMain,
            queryParameters,
            hash
        )
