import { ArticleUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const ArticleUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        hash
    }: NextUrl<ArticleUrlFragment>): URL =>
        nextUrl(
            `/insights/${slug}`,
            siteContext.siteUrlMain,
            queryParameters,
            hash
        )
