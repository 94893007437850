import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuArticleLinkFragment } from 'generated/graphql'
import { ArticleLink } from 'content-types/Article/Article.Link/Article.Link'

export const ComponentMenuArticleLink: MenuObjectView<ComponentMenuArticleLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        article,
        queryParameters,
        fragment,
        siteContext
    }) =>
        maybe(article, article =>
            <li data-variant={variant?.variant}>
                <ArticleLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...article}
                >
                    { linkText ?? article.title }
                </ArticleLink>
            </li>
        )
