import { FC } from 'react'
import { maybe } from 'utility/utility'
import { ComponentImagePictureFragment, Enum_Componentimagepicture_Sizingmode as SizingMode } from 'generated/graphql'
import { UploadFileMedia } from 'content-types/UploadFile/UploadFile.Media/UploadFile.Media'
import { ComponentImageFocus } from 'components/image/ComponentImageFocus/ComponentImageFocus'

export const ComponentImagePicture: FC<ComponentImagePictureFragment & {
    className?: string,
    sizes?: string,
    loading?: 'lazy' | 'eager'
}> =
    ({
        title,
        image,
        imageFocus,
        className,
        flip,
        embedSVG,
        sizingMode,
        sizes,
        loading,
        videoControls
    }) =>
        maybe(image, image =>
            <UploadFileMedia
                {...image}
                embedSVG={embedSVG ?? true}
                style={{
                    objectPosition: ComponentImageFocus(imageFocus),
                    objectFit:
                        image.mime === 'image/svg+xml' ? 'contain' :
                        sizingMode === SizingMode.Contain ? 'contain' :
                        sizingMode === SizingMode.Cover ? 'cover' :
                        undefined
                }}
                className={`${className ?? ''} ${ ( flip ?? false ) ? 'flipx' : '' } `}
                alternativeText={title}
                sizes={sizes}
                loading={loading}
                showControls={videoControls?.showControls}
                poster={videoControls?.poster?.data?.attributes ?? undefined}
            />
        )
