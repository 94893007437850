import { MagazineUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const MagazineUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        hash
    }: NextUrl<MagazineUrlFragment>): URL =>
        nextUrl(
            `/${slug}`,
            siteContext.siteUrlMagazines,
            queryParameters,
            hash
        )
