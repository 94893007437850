import {
    ComponentMenuActivityLinkFragment,
    ComponentMenuArticleLinkFragment,
    ComponentMenuDropdownMenuFragment,
    ComponentMenuEventLinkFragment,
    ComponentMenuEventSeriesLinkFragment,
    ComponentMenuFormLinkFragment,
    ComponentMenuHomeLinkFragment,
    ComponentMenuPageLinkFragment,
    ComponentMenuEventSeriesPageLinkFragment,
    ComponentMenuMagazineLinkFragment,
    ComponentMenuMagazinePageLinkFragment,
    ComponentMenuPortfolioLinkFragment,
    ComponentMenuTopicLinkFragment,
    ComponentMenuExternalLinkFragment,
    ComponentMenuExternalImageLinkFragment,
    ComponentMenuSocialMediaChannelLinkFragment,
    ComponentBodyProseFragment
} from 'generated/graphql'

export type MenuDynamicZone =
    (
        (   ComponentMenuActivityLinkFragment
          | ComponentMenuArticleLinkFragment
          | ComponentMenuDropdownMenuFragment
          | ComponentMenuEventLinkFragment
          | ComponentMenuEventSeriesLinkFragment
          | ComponentMenuFormLinkFragment
          | ComponentMenuHomeLinkFragment
          | ComponentMenuPageLinkFragment
          | ComponentMenuEventSeriesPageLinkFragment
          | ComponentMenuMagazineLinkFragment
          | ComponentMenuMagazinePageLinkFragment
          | ComponentMenuPortfolioLinkFragment
          | ComponentMenuTopicLinkFragment
          | ComponentMenuExternalLinkFragment
          | ComponentMenuExternalImageLinkFragment
          | ComponentMenuSocialMediaChannelLinkFragment
          | ComponentBodyProseFragment
        ) & {
            contextStyles?: { readonly [key: string]: string }
        }
    )
    | null

import { ComponentMenuActivityLink } from 'components/menu/ComponentMenuActivityLink/ComponentMenuActivityLink'
import { ComponentMenuArticleLink } from 'components/menu/ComponentMenuArticleLink/ComponentMenuArticleLink'
import { ComponentMenuDropdownMenu } from 'components/menu/ComponentMenuDropdownMenu/ComponentMenuDropdownMenu'
import { ComponentMenuEventLink } from 'components/menu/ComponentMenuEventLink/ComponentMenuEventLink'
import { ComponentMenuEventSeriesLink } from 'components/menu/ComponentMenuEventSeriesLink/ComponentMenuEventSeriesLink'
import { ComponentMenuFormLink } from 'components/menu/ComponentMenuFormLink/ComponentMenuFormLink'
import { ComponentMenuHomeLink } from 'components/menu/ComponentMenuHomeLink/ComponentMenuHomeLink'
import { ComponentMenuPageLink } from 'components/menu/ComponentMenuPageLink/ComponentMenuPageLink'
import { ComponentMenuEventSeriesPageLink } from 'components/menu/ComponentMenuEventSeriesPageLink/ComponentMenuEventSeriesPageLink'
import { ComponentMenuMagazineLink } from 'components/menu/ComponentMenuMagazineLink/ComponentMenuMagazineLink'
import { ComponentMenuMagazinePageLink } from 'components/menu/ComponentMenuMagazinePageLink/ComponentMenuMagazinePageLink'
import { ComponentMenuPortfolioLink } from 'components/menu/ComponentMenuPortfolioLink/ComponentMenuPortfolioLink'
import { ComponentMenuTopicLink } from 'components/menu/ComponentMenuTopicLink/ComponentMenuTopicLink'
import { ComponentMenuExternalLink } from 'components/menu/ComponentMenuExternalLink/ComponentMenuExternalLink'
import { ComponentMenuExternalImageLink } from 'components/menu/ComponentMenuExternalImageLink/ComponentMenuExternalImageLink'
import { ComponentMenuSocialMediaChannelLink } from 'components/menu/ComponentMenuSocialMediaChannelLink/ComponentMenuSocialMediaChannelLink'

import { ComponentBodyProse } from 'components/body/ComponentBodyProse/ComponentBodyProse'

import { MenuObjectView } from 'utility/utility'

export const MenuComponent: MenuObjectView<MenuDynamicZone> =
    (component) => {

        const { __typename } = component

        switch (__typename) {

            case 'ComponentMenuActivityLink':
                return <ComponentMenuActivityLink {...component} />

            case 'ComponentMenuArticleLink':
                return <ComponentMenuArticleLink {...component} />
            
            case 'ComponentMenuDropdownMenu':
                return <ComponentMenuDropdownMenu {...component} />

            case 'ComponentMenuEventLink':
                return <ComponentMenuEventLink {...component} />

            case 'ComponentMenuEventSeriesLink':
                return <ComponentMenuEventSeriesLink {...component} />

            case 'ComponentMenuFormLink':
                return <ComponentMenuFormLink {...component} />

            case 'ComponentMenuHomeLink':
                return <ComponentMenuHomeLink {...component} />

            case 'ComponentMenuPageLink':
                return <ComponentMenuPageLink {...component} />

            case 'ComponentMenuEventSeriesPageLink':
                return <ComponentMenuEventSeriesPageLink {...component} />

            case 'ComponentMenuMagazineLink':
                return <ComponentMenuMagazineLink {...component} />

            case 'ComponentMenuMagazinePageLink':
                return <ComponentMenuMagazinePageLink {...component} />

            case 'ComponentMenuPortfolioLink':
                return <ComponentMenuPortfolioLink {...component} />

            case 'ComponentMenuTopicLink':
                return <ComponentMenuTopicLink {...component} />

            case 'ComponentMenuExternalLink':
                return <ComponentMenuExternalLink {...component} />

            case 'ComponentMenuExternalImageLink':
                return <ComponentMenuExternalImageLink {...component} />

            case 'ComponentMenuSocialMediaChannelLink':
                return <ComponentMenuSocialMediaChannelLink {...component} />

            case 'ComponentBodyProse':
                return <li>
                    <ComponentBodyProse {...component} />
                </li>

            default:
                return <></>

        }

    }
