import { ActivityUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const ActivityUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        hash
    }: NextUrl<ActivityUrlFragment>): URL =>
        nextUrl(
            `/${slug}`,
            siteContext.siteUrlMain,
            queryParameters,
            hash
        )
