import { MenuObjectView } from 'utility/utility'
import { ComponentMenuDropdownMenuFragment } from 'generated/graphql'
import { MenuContent } from 'components/menu/MenuContent/MenuContent'
import { MenuDynamicZone } from 'components/menu/MenuComponent/MenuComponent'

import styles from "./ComponentMenuDropdownMenu.module.scss"

import ChevronDown from 'public/icons/chevron-down.svg'

export const ComponentMenuDropdownMenu: MenuObjectView<ComponentMenuDropdownMenuFragment> =
    ({
        id,
        overrideHeader,
        menu,
        pageContext,
        siteContext,
        activeComponent = '',
        setActiveComponent
    }) => {

        return (
            <li
                className={styles.Menu}
                data-open={ activeComponent === id }
            >
                <button
                    className={styles.Button}
                    onClick={() =>
                            setActiveComponent == undefined ? undefined :
                                setActiveComponent( activeComponent === id ? '' : id )
                    }
                >
                    { overrideHeader ?? menu?.data?.attributes?.header ?? ''}
                    <ChevronDown className={styles.Chevron}/>
                </button>
                <nav className={styles.Dropdown}>
                    {
                        menu?.data?.attributes?.links === undefined ? <></> :
                            <MenuContent
                                menuHeading=''
                                siteContext={siteContext}
                                pageContext={pageContext}
                                articleContext={{}}
                                menu={menu?.data?.attributes?.links as MenuDynamicZone[]}
                            />
                    }
                </nav>
            </li>
        )
    }
