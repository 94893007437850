import { MenuObjectView } from 'utility/utility'
import { maybe } from 'utility/utility'
import { ComponentMenuTopicLinkFragment } from 'generated/graphql'
import { TopicLink } from 'content-types/Topic/Topic.Link/Topic.Link'

export const ComponentMenuTopicLink: MenuObjectView<ComponentMenuTopicLinkFragment> =
    ({
        linkText,
        variant,
        contextStyles,
        topic,
        queryParameters,
        fragment,
        siteContext
    }) =>
        maybe(topic, topic =>
            <li data-variant={variant?.variant}>
                <TopicLink
                    className={contextStyles?.Link}
                    queryParameters={queryParameters}
                    hash={fragment ?? ""}
                    siteContext={siteContext}
                    {...topic}
                >
                    { linkText ?? topic.linkOverrideLabel ?? topic.title }
                </TopicLink>
            </li>
        )
