import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

import { DatasetUrlFragment } from 'generated/graphql'

export const DatasetUrl =
    ({
        slug,
        siteContext,
        queryParameters,
        hash
    }: NextUrl<DatasetUrlFragment>): URL =>
        nextUrl(
            `/market-intelligence/dataset/${slug}`,
            siteContext.siteUrlMain,
            queryParameters,
            hash
        )
