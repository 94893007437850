'use client'

import { FormObjectView, key, sanitiseName } from 'utility/utility'
import { ComponentFormEmailFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

export const ComponentFormEmailField: FormObjectView<ComponentFormEmailFieldFragment> =
    ({
        name,
        label,
        placeholder,
        required,
        ...object
    }) => {

        const { register, formState: { errors } } = useFormContext()

        const sanitisedName = sanitiseName(name)

        return (
            <>
                <label>
                    <input
                        type='text'
                        id={key(object)}
                        placeholder={placeholder ?? label}
                        {...register(sanitisedName, {
                            pattern: {
                                // eslint-disable-next-line no-control-regex
                                value: /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
                                message: 'Please enter a valid email address'
                            },
                            required: 'This field is required',
                            setValueAs: (v: string): string => v.trim()
                        })}
                    />
                    <span>{label}</span>
                </label>
                {
                    !required || errors[sanitisedName] === undefined ? <></> :
                        <span>{ ( errors[sanitisedName]?.message as string | undefined ) ?? 'Please enter a valid value' }</span>
                }
            </>
        )

    }
