import { ComponentImageFocusFragment } from 'generated/graphql'

const percentage = (position = 'centre'): string => {
    switch (position) {
        case 'top':
        case 'left':
            return '0%'
        case 'upper':
        case 'centre-left':
            return '25%'
        case 'centre':
            return '50%'
        case 'lower':
        case 'centre-right':
            return '75%'
        case 'bottom':
        case 'right':
            return '100%'
        default:
            return '50%'
    }
}

export const ComponentImageFocus = (focus: ComponentImageFocusFragment | null): string =>
    `${percentage(focus?.horizontal ?? 'centre')} ${percentage(focus?.vertical ?? 'centre')}`
