export interface KeyedObject {
    __typename: string,
    id: string,
    linkText?: string | null
}

export const key =
    ({ __typename, id}: KeyedObject) =>
        `${__typename}${id}`

export const labelKey =
        ({ __typename, id}: KeyedObject) =>
            `label_${__typename}${id}`
