'use client'

import { useState } from 'react'

import { type MenuDynamicZone, MenuComponent } from 'components/menu/MenuComponent/MenuComponent'
import { DynamicZoneView, key } from 'utility/utility'

import styles from './MenuContent.module.scss'

type MenuContentData = {
    menuHeading?: string,
    menu: MenuDynamicZone[],
    contextStyles?: { readonly [key: string]: string }
}

export const MenuContent: DynamicZoneView<MenuContentData> =
    ({
        menuHeading,
        menu,
        siteContext,
        pageContext,
        articleContext,
        contextStyles
    }) => {

        // This state is used to manage drop-down menu components
        const [activeComponent, setActiveComponent] = useState<string>('')

        return (
            <section className={`${styles.MenuLinksList} ${contextStyles?.MenuLinksList ?? ''} `}>
                { menuHeading !== '' && <h1>{menuHeading}</h1> }
                <ul>
                    {
                        menu
                            .filter( component => component !== null && ( component.hidden === null || !component.hidden ) )
                            .map( component =>
                                component === null ? <></> :
                                    <MenuComponent
                                        key={key(component)}
                                        siteContext={siteContext}
                                        pageContext={pageContext}
                                        articleContext={articleContext}
                                        activeComponent={activeComponent}
                                        setActiveComponent={setActiveComponent}
                                        contextStyles={contextStyles}
                                        {...component}
                                    />
                            )
                    }
                </ul>
            </section>
        )

    }

