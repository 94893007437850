
import { ContentTypeLink } from 'utility/utility'
import { MagazinePageIdLinkFragment } from 'generated/graphql'
import { type PageContext } from 'context/PageContext'
import { MagazinePageLink } from 'content-types/MagazinePage/MagazinePage.Link/MagazinePage.Link'

export const MagazinePageIdLink: ContentTypeLink<MagazinePageIdLinkFragment & {
    path: string,
    override?: string,
    pageContext: PageContext
}> =
    ({
        path,
        siteContext,
        pageContext,
        override,
        ...page
    }) => {

        const { allMagazinePages } = pageContext

        const target = allMagazinePages?.data.find( p => p.id === path )

        return (
            target?.attributes?.slug === undefined ? <span>{page.children}</span> :
                <MagazinePageLink
                    siteContext={siteContext}
                    {...{...page,...target.attributes}}
                />
        )

    }
