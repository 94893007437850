import { FC, PropsWithChildren } from 'react'
import NextLink from 'next/link'

import { SiteContext } from 'context/SiteContext'

export const Link: FC<PropsWithChildren<{
    href: URL
    className?: string
    title?: string
    prefix?: string
    prefetch?: boolean
    replace?: boolean
    scroll?: boolean
    target?: string
    override?: string
    siteContext: SiteContext
}>> = ({
    prefix,
    children,
    siteContext,
    override,
    ...link
}) =>
    link.href.toString().startsWith(siteContext.siteUrl) ?
        <NextLink data-prefix={prefix} data-override={override} {...link}>
            {children}
        </NextLink>
    :
        <a
            href={link.href.toString()}
            className={override == "text" ? "textLink" : link.className}
            title={link.title}
            target={link.target ?? '_blank'}
        >
            { children }
        </a>
